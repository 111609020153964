import { Typography } from '@mui/material';
import * as React from "react";
import styled from "styled-components";

const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled(Typography)`
&.MuiTypography-h2 {
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
  }
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Content2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const PlaceholderImageIcon = styled.img`
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 40rem;
  object-fit: cover;
  margin-left: -3.125rem;
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const LayoutRoot = styled.section`
  align-self: stretch;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  text-align: center;
  font-size: var(--text-regular-light-size);
  color: var(--border-primary);
  font-family: var(--text-regular-light);
`;

const FeatureStructure = () => {
  return (
    <LayoutRoot>
      <Container>
        <Content2>
          <Content1>
            <SectionTitle>
              <Subheading>STRUCTURE</Subheading>
              <Content>
                <Heading variant='h2'>MANKAYAN OWNERSHIP STRUCTURE</Heading>
                <Text1>
                  The Mankayan Project ownership structure meets local
                  regulations while maintaining flexibility for future control
                  and financial benefits. This ownership structure involves
                  subsidiaries and provides the company with influence over key
                  decisions.
                </Text1>
              </Content>
            </SectionTitle>
          </Content1>
        </Content2>
        <PlaceholderImageIcon alt="" src="/IDMOrganizationChart.gif" />
      </Container>
    </LayoutRoot>
  );
};

export default FeatureStructure;
