import * as React from "react";
import { useState, useCallback } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import Timeline from "../components/timeline";
import FeatureOverview from "../components/feature-overview";
import TeamGroup from "../components/team-group";
import FeatureStructure from "../components/feature-structure";
import CTA from "../components/c-t-a";
import FAQ from "../components/f-a-q";
import ContainerheaderMainNav from '../components/containerheader-main-nav';
import Footer1 from '../components/footer1';


const Button1 = styled(Button)`
  cursor: pointer;
`;
const Button2 = styled(Button)``;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  gap: var(--gap-base);
`;

const Tagline = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const UnleashingPotentialTogether = styled(Typography)`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 600;
`;
const WeAreCommitted = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  text-transform: uppercase;
  font-family: var(--text-tiny-normal);
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h1-size);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 43.75rem;
`;

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-21xl);
  gap: var(--gap-5xl);
`;
const Header = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url("/header--65@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--text-regular-normal-size);
  color: var(--text-alternate);
  font-family: var(--text-tiny-normal);
`;

const AboutUsRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const AboutUs = () => {
  const [navLinkDropdown1AnchorEl, setNavLinkDropdown1AnchorEl] =
    useState(null);
  const navLinkDropdown1Open = Boolean(navLinkDropdown1AnchorEl);
  const handleNavLinkDropdown1Click = (event) => {
    setNavLinkDropdown1AnchorEl(event.currentTarget);
  };
  const handleNavLinkDropdown1Close = () => {
    setNavLinkDropdown1AnchorEl(null);
  };

  const onButtonmuitext01Click = useCallback(() => {
    navigate("/");
  }, []);

  const onNavLinkDropdown1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButtonmuitext02Click = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  const onButtonContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='timeline21']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onFAQsTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onHomeTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onAboutTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onProjectTextClick = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  const onInvestorsTextClick = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  return (
    <AboutUsRoot>
      <Header>
        <ContainerheaderMainNav />
        <Container>
          <SectionTitle>
            <Tagline>Discover</Tagline>
            <Content1>
              <UnleashingPotentialTogether variant='h1' sx={{ fontWeight: '600', fontStyle: 'normal' }} >
                Unleashing Potential, Together
              </UnleashingPotentialTogether>
              <WeAreCommitted>
                We are committed to unlocking the full potential of the Mankayan
                Project through collaboration and innovation.
              </WeAreCommitted>
            </Content1>
          </SectionTitle>
          <Actions>
            <Button1
              disableElevation={true}
              color="primary"
              variant="contained"
              sx={{ borderRadius: "0px 0px 0px 0px" }}
              href='/'
            >
              Learn More
            </Button1>
            <Button2
              disableElevation={true}
              color="primary"
              variant="outlined"
              href='javascript:void(Tawk_API.toggle())'
            >
              Get in touch
            </Button2>
          </Actions>
        </Container>
      </Header>
      <Timeline />
      <FeatureOverview />
      <TeamGroup />
      <FeatureStructure />
      <CTA />
      <FAQ />
      <Footer1 />
      
    </AboutUsRoot>
  );
};

export default AboutUs;
