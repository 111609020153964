import { Typography } from '@mui/material';
import * as React from "react";
import styled from "styled-components";
import clsx from 'clsx';

const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled(Typography)`
&.MuiTypography-h2 {
  color: var(--primary-highlight);
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
  }
`;
const LoremIpsumDolor = styled.div`
  width: 48rem;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
  display: none;
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-main);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const PlaceholderImageIcon = styled.img`
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 18rem;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Name1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const JobTitle = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const IconLinkedin = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const IconDribble = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  display: none;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-45xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const JobTitle1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  white-space: pre-wrap;
`;
const Card1 = styled.div`
  flex: 1;
  height: 18.063rem;
  max-width: 39rem;
  @media screen and (max-width: 960px) {
    display: none;
    flex: unset;
    align-self: stretch;
  }
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-45xl);
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--text-large-semi-bold-size);
`;
const Team = styled.div`
  align-self: stretch;
  background-color: var(--color-gray-500);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  @media screen and (max-width: 960px) {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
`;
const LoremIpsumDolor1 = styled.div`
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
  display: inline-block;
  max-width: 37.5rem;
`;
const Content3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-main);
  font-family: var(--heading-desktop-h4);
`;
const PlaceholderImageIcon1 = styled.img`
  width: 11.331rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 18rem;
  object-fit: cover;
  display: none;
`;
const Name2 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 600;
`;
const Column1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Card2 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const SocialIcons1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-17xl) 0rem 0rem;
  gap: var(--gap-sm);
`;
const Container1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Content4 = styled.div`
  align-self: stretch;
  // height: 25.563rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--text-large-semi-bold-size);
`;
const Team1 = styled.div`
  align-self: stretch;
  background-color: var(--color-gray-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  @media screen and (max-width: 960px) {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
`;
const TeamGroupRoot = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--text-regular-light-size);
  color: var(--border-primary);
  font-family: var(--text-regular-light);
`;

const TeamGroup = () => {
  return (
    <TeamGroupRoot>
      <Team>
        <SectionTitle>
          <Subheading>Experienced</Subheading>
          <Content>
            <Heading variant='h2' sx={{ textShadow: '0px 0px 2px #5a5a5a' }} >Our Board</Heading>
            <LoremIpsumDolor>
              Join our team and be part of our exciting mining projects.
            </LoremIpsumDolor>
          </Content>
        </SectionTitle>
        <Content2>
          <Container>
            <Row>
              <Card>
                {/* <PlaceholderImageIcon
                  alt=""
                  src="/placeholder-image23@2x.png"
                /> */}
                <Column>
                  <Content1>
                    <Title>
                      <Name1>Geoff Gilmour</Name1>
                      <JobTitle>Chairman</JobTitle>
                    </Title>
                    <Text1>
                      30 years experience in the mining sector and instrumental
                      in the creation of Andean Resources Ltd ($3.56bn takeover
                      by Goldcorp in 2010). Previously the Managing Director of
                      Rift Valley, Amex Resources Ltd and Brightstar Resources
                      Ltd.
                    </Text1>
                  </Content1>
                  <SocialIcons>
                    <IconLinkedin alt="" src="/icon--linkedin1.svg" />
                    <IconLinkedin alt="" src="/icon--x1.svg" />
                    <IconDribble alt="" src="/icon--dribble.svg" />
                  </SocialIcons>
                </Column>
              </Card>
              <Card>
                {/* <PlaceholderImageIcon
                  alt=""
                  src="/placeholder-image24@2x.png"
                /> */}
                <Column>
                  <Content1>
                    <Title>
                      <Name1>Greg Cunnold</Name1>
                      <JobTitle>Technical Director</JobTitle>
                    </Title>
                    <Text1>{`Geologist with >25 years of experience in the international exploration industry for precious and base metals as well as some bulks and specialty minerals. Worked in numerous countries in Australasia, Africa, SE Asia and Eastern Europe.`}</Text1>
                  </Content1>
                  <SocialIcons>
                    <IconLinkedin alt="" src="/icon--linkedin1.svg" />
                    <IconLinkedin alt="" src="/icon--x1.svg" />
                    <IconDribble alt="" src="/icon--dribble.svg" />
                  </SocialIcons>
                </Column>
              </Card>
              <Card>
                {/* <PlaceholderImageIcon
                  alt=""
                  src="/placeholder-image25@2x.png"
                /> */}
                <Column>
                  <Content1>
                    <Title>
                      <Name1>Olly Cairns</Name1>
                      <JobTitle1>{`Executive Director       `}</JobTitle1>
                    </Title>
                    <Text1>{`Corporate financier with >20 years International capital markets experience across the LSE, ASX. Managing Director of corporate advisory firm Pursuit Capital. Founder of Galena Mining Ltd, previous director of Vmoto Ltd.`}</Text1>
                  </Content1>
                  <SocialIcons>
                    <IconLinkedin alt="" src="/icon--linkedin1.svg" />
                    <IconLinkedin alt="" src="/icon--x1.svg" />
                    <IconDribble alt="" src="/icon--dribble.svg" />
                  </SocialIcons>
                </Column>
              </Card>
            </Row>
            {/* <Row>
              <Card>
                <PlaceholderImageIcon
                  alt=""
                  src="/placeholder-image25@2x.png"
                />
                <Column>
                  <Content1>
                    <Title>
                      <Name1>Olly Cairns</Name1>
                      <JobTitle1>{`Executive Director       `}</JobTitle1>
                    </Title>
                    <Text1>{`Corporate financier with >20 years International capital markets experience across the LSE, ASX. Managing Director of corporate advisory firm Pursuit Capital. Founder of Galena Mining Ltd, previous director of Vmoto Ltd.`}</Text1>
                  </Content1>
                  <SocialIcons>
                    <IconLinkedin alt="" src="/icon--linkedin1.svg" />
                    <IconLinkedin alt="" src="/icon--x1.svg" />
                    <IconDribble alt="" src="/icon--dribble.svg" />
                  </SocialIcons>
                </Column>
              </Card>
              <Card1 />
            </Row> */}
          </Container>
        </Content2>
      </Team>
      <Team1>
        <SectionTitle>
          <Subheading>In Country Management</Subheading>
          <Content3>
            <Heading variant='h2'>KEY PERSONNEL</Heading>
            <LoremIpsumDolor1>
              IDM possesses all the necessary local resources and expertise to
              advance the development of Mankayan.
            </LoremIpsumDolor1>
          </Content3>
        </SectionTitle>
        <Content4>
          <Container1>
            <Row>
              <Card2>
                <PlaceholderImageIcon1
                  alt=""
                  src="/placeholder-image26@2x.png"
                />
                <Column1>
                  <Content1>
                    <Title>
                      <Name2>JOHAN RAADSMA</Name2>
                      <JobTitle> IN COUNTRY DIRECTOR</JobTitle>
                    </Title>
                    <Text1>
                      35 years of experience in mining, civil construction and
                      resource development. He graduated from University of New
                      South Wales with a BE in Mining and is a current member of
                      AusIMM (#203214). Mr. Raadsma has held Senior Management
                      and Directorship positions in several companies in
                      Australia, Philippines, Indonesia, USA and West Africa. He
                      is currently a Trustee of the Philippine Mining and
                      Exploration Association and Chairman of B2Gold’s
                      200,000ozs per annum gold processing facility at Masbate.
                    </Text1>
                  </Content1>
                  <SocialIcons>
                    <IconLinkedin alt="" src="/icon--linkedin1.svg" />
                    <IconLinkedin alt="" src="/icon--x1.svg" />
                    <IconDribble alt="" src="/icon--dribble.svg" />
                  </SocialIcons>
                </Column1>
              </Card2>
              <Card>
                <PlaceholderImageIcon1
                  alt=""
                  src="/placeholder-image27@2x.png"
                />
                <Column1>
                  <Content1>
                    <Title>
                      <Name2>JOEY AYSON</Name2>
                      <JobTitle>IN COUNTRY GEOLOGIST</JobTitle>
                    </Title>
                    <Text1>{`35 years of experience in the mineral industry from grassroots exploration to project development and management and government liaison.  He graduated BSc Geology from the University of the Philippines. He is the President of the Philippine Mining & Exploration Association (PMEA) and Chair of the UP Geology Alumni Association (UPGAA). He is also the President the Geological Society of the Philippines (GSP) and a member of AusIMM (#320863). He is an Accredited Competent Person for Geology under the Philippine Mineral Reporting Code (PMRC). He is also an accredited Independent Verifier for the Towards Sustainable Mining (TSM) of the Chamber of Mines. He previously worked with Pelsart Resources, Scorpion Resources and Oxiana Limited.`}</Text1>
                  </Content1>
                  <SocialIcons>
                    <IconLinkedin alt="" src="/icon--linkedin1.svg" />
                    <IconLinkedin alt="" src="/icon--x1.svg" />
                    <IconDribble alt="" src="/icon--dribble.svg" />
                  </SocialIcons>
                </Column1>
              </Card>
              <Card2>
                <PlaceholderImageIcon1
                  alt=""
                  src="/placeholder-image28@2x.png"
                />
                <Column1>
                  <Content1>
                    <Title>
                      <Name2>{`RONNIE SIAPNO `}</Name2>
                      <JobTitle>IN COUNTRY MINING ENGINEER</JobTitle>
                    </Title>
                    <Text1>
                      A Mining Engineering graduate from Saint Louis University,
                      Ronnie is currently the President of Crescent Mining and
                      Development Corporation and Director of Bezant Resources
                      Inc. Prior to this he was the President of Pacific Falkon
                      (Phils), Inc, and was Mine Manager of Multistone Mining
                      Corporation. Additionally he was also an Engineer for
                      Portman Mining (Phils), Inc and Benguet Exploration
                      Thanksgiving Mine. Ronnie is also a lifetime member of the
                      Philippine Society of Mining Engineers.
                    </Text1>
                  </Content1>
                  <SocialIcons>
                    <IconLinkedin alt="" src="/icon--linkedin1.svg" />
                    <IconLinkedin alt="" src="/icon--x1.svg" />
                    <IconDribble alt="" src="/icon--dribble.svg" />
                  </SocialIcons>
                </Column1>
              </Card2>
            </Row>
          </Container1>
        </Content4>
      </Team1>
    </TeamGroupRoot>
  );
};

export default TeamGroup;
