import { Typography } from '@mui/material';
import * as React from "react";
import styled from "styled-components";

const PlaceholderImageIcon = styled.img`
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 40rem;
  object-fit: cover;
`;

const Heading = styled(Typography)`
&.MuiTypography-h2 {
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
  }
`;
const AdditionallyThereAre = styled.p`
  margin-block-start: 0;
  margin-block-end: 16px;
`;
const ThereAreNo = styled.p`
  margin: 0;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--text-alternate);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Heading1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  font-weight: 600;
`;
const WithItsWorldClass = styled.p`
  margin-block-start: 0;
  margin-block-end: 8px;
`;
const TheCompanysFocus = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;
const LoremIpsumDolorContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
`;
const ListItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const List = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0rem;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--text-large-semi-bold-size);
  font-family: var(--heading-desktop-h4);
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Button1 = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 300;
`;
const Button2 = styled.div`
  border: 1px solid var(--text-alternate);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Button3 = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--border-primary);
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--text-medium-light-size);
`;
const Content2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-61xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const LayoutRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  text-align: center;
  font-size: var(--text-regular-light-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
`;

const FeatureOverview = () => {
  return (
    <LayoutRoot>
      <Container>
        <PlaceholderImageIcon alt="" src="/placeholder-image22@2x.png" />
        <Content2>
          <Content1>
            <SectionTitle>
              {/* <Subheading>IDM</Subheading> */}
              <Content>
                <Heading variant='h2'>CORPORATE OVERVIEW</Heading>
                <Text1>
                  <AdditionallyThereAre>
                    IDM has 84,416,560 shares issued to approximately 800
                    shareholders.
                  </AdditionallyThereAre>
                  <AdditionallyThereAre>
                    Additionally there are 3,800,000 options available at 20
                    cents with a 4-year expiration and 6,620,000 options at 40
                    cents with a 4-year expiration.
                  </AdditionallyThereAre>
                  <ThereAreNo>{`There are no performance rights issued. `}</ThereAreNo>
                </Text1>
              </Content>
            </SectionTitle>
            <List>
              <ListItem>
                <Heading1>
                  World Class Project - Copper the Right Commodity
                </Heading1>
                <LoremIpsumDolorContainer>
                  <WithItsWorldClass>{`With its world-class copper project IDM is now in a strong position to join the ASX. `}</WithItsWorldClass>
                  <WithItsWorldClass>{`Copper is a highly sought-after commodity in the global market especially with the increasing demand for renewable energy technologies. `}</WithItsWorldClass>
                  <WithItsWorldClass>{`IDM's project is strategically positioned to capitalize on this demand making it an attractive investment opportunity for potential shareholders. `}</WithItsWorldClass>
                  <TheCompanysFocus>{`The company's focus on copper also aligns with the current market trends further enhancing its appeal for a liquidity event or IPO.  `}</TheCompanysFocus>
                </LoremIpsumDolorContainer>
              </ListItem>
              <ListItem>
                <Heading1>
                  <ThereAreNo>Compelling Value and Strong Upside</ThereAreNo>
                </Heading1>
                <LoremIpsumDolorContainer>
                  <WithItsWorldClass>{`IDM's strong footprint in the country where its project is located provides a solid foundation for future growth and development. `}</WithItsWorldClass>
                  <WithItsWorldClass>{`With clear company goals and a track record of successful operations IDM is well-positioned to attract investors and drive shareholder value through further corporate action. `}</WithItsWorldClass>
                </LoremIpsumDolorContainer>
              </ListItem>
            </List>
          </Content1>
          {/* <Actions>
            <Button2>
              <Button1>Learn More</Button1>
            </Button2>
            <Button3>
              <Button1>Sign Up</Button1>
              <IconChevronRight alt="" src="/icon--chevronright2.svg" />
            </Button3>
          </Actions> */}
        </Content2>
      </Container>
    </LayoutRoot>
  );
};

export default FeatureOverview;
